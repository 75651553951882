import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/accropress-theme-alfred/src/components/layout';
import { Link } from 'gatsby';

import Modal from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';

import '@accrosoft-ltd/accropress-theme-alfred/src/components/wig-bundle.scss';

import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';

import NoticeTemplate from '@accrosoft-ltd/accropress-theme-alfred/src/templates/NoticeTemplate';
import BasicSlider from '@accrosoft-ltd/accropress-theme-alfred/src/components/BasicSlider';

import buildMediaContent from '@accrosoft-ltd/accropress-websites-components/dist/esm/buildMediaContent';

import ExtraHomepageContentTop from '@accrosoft-ltd/accropress-theme-alfred/src/templates/HomepageComponents/extraHomepageContentTop';
import ExtraHomepageContentBottom from '@accrosoft-ltd/accropress-theme-alfred/src/templates/HomepageComponents/extraHomepageContentBottom';
import HeroInnerContent from '@accrosoft-ltd/accropress-theme-alfred/src/templates/HomepageComponents/heroInnerContent';

import loadable from '@loadable/component';
const WigLoader = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader')
);
const WeducNewsFeed = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/weducNewsfeed')
);
const WeducDairyDates = loadable(() =>
  import(
    '@accrosoft-ltd/accropress-websites-components/dist/esm/weducDairyDates'
  )
);

require('es6-promise').polyfill();
var moment = require('moment');

const gatsbyConfig = require('@accrosoft-ltd/accropress-theme-alfred/theme-config.js');

const gatsbyConfigSiteMetaData = gatsbyConfig.siteMetadata;
const gatsbyConfigPlugins = gatsbyConfig.plugins;
const gatsbyConfigPluginsAP = gatsbyConfigPlugins.find(
  (o) => o.resolve === 'gatsby-plugin-accropress'
);

const gatsbyConfigPluginsAPOptions =
  gatsbyConfigPluginsAP &&
  gatsbyConfigPluginsAP.resolve === 'gatsby-plugin-accropress' &&
  gatsbyConfigPluginsAP.options
    ? gatsbyConfigPluginsAP.options
    : {};

export default function HomepagePagePage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navResultData,
    pluginOptions,
  },
  props,
}) {
  const config = pluginOptions;

  const [toggled, setToggled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [noticesMainLoaded, setNoticesMainLoaded] = useState(false);
  const [noticesEmergencyLoaded, setNoticesEmergencyLoaded] = useState(false);
  const [newsfeedLoaded, setNewsfeedLoaded] = useState(false);
  const [calendarEventsLoaded, setCalendarEventsLoaded] = useState(false);

  const [noticesBannerExpanded, setNoticesBannerExpanded] = useState(false);

  const [videoPlayerModalTriggered, setVideoPlayerModalTriggered] = useState(
    false
  );

  useEffect(() => {
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return (
          navigator.userAgent.match(/IEMobile/i) ||
          navigator.userAgent.match(/WPDesktop/i)
        );
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      },
    };

    var video = document.getElementById('hero_video_player');

    if (!isMobile.any() && video) {
      // get reference to video sources
      var sources = document.getElementsByClassName('video__source');

      // loop through and replace data-src with src
      for (var i = 0; i < sources.length; i++) {
        if (sources[i].getAttribute('data-src')) {
          sources[i].setAttribute('src', sources[i].getAttribute('data-src'));
          sources[i].removeAttribute('data-src'); // use only if you need to remove data-src attribute after setting src
        }
      }

      video.muted = true;

      // listen for canplay event and fade video in
      video.addEventListener('canplay', function () {
        video.style.transition = 'opacity 2s';
        video.style.opacity = 1;
      });

      // reload video sources
      video.load();
      video.play();
    }
  }, [videoPlayerModalTriggered]);

  const toggleNoticesBanner = function () {
    if (noticesBannerExpanded) {
      setNoticesBannerExpanded(false);
    } else {
      setNoticesBannerExpanded(true);
    }
  };

  const updateAndShowModal = (title, description, postType, attachment) => {
    setModalData({
      title: title,
      description: description,
      postType: postType,
      attachment: attachment,
    });
    setModalOpen(true);
  };

  console.debug('contentDataData', contentDataData);

  return (
    <Layout
      {...props}
      navResultData={navResultData}
      contentDataData={contentDataData}
      integratorData={integratorData}
      contentMetaData={contentMetaData}
      pluginOptions={pluginOptions}
    >
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
        gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
        gatsbyConfigPluginsAPOptions={gatsbyConfigPluginsAPOptions}
      />
      <Modal
        classNames={{ modal: 'AccroModal' }}
        open={modalOpen && modalData ? true : false}
        onClose={() => setModalOpen(false)}
        center
      >
        {modalData && (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: modalData.description }}
            ></div>
            {modalData.attachment && modalData.attachment.length > 0 && (
              <ul className="AccroModal_Attachments">
                {modalData.attachment.map((attachment) => {
                  return (
                    <li className="AccroModal_Attachments_Attachement">
                      <a
                        href={attachment.external_url}
                        title={attachment.title}
                        target="_blank"
                        rel="noopener noreferer"
                      >
                        {attachment.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: buildMediaContent(modalData.postType),
              }}
            ></div>
          </>
        )}
      </Modal>

      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          {pluginOptions.useHeroSlider ? (
            <BasicSlider
              OverlayContainer={
                <HeroInnerContent
                  toggled={toggled}
                  setToggled={setToggled}
                  noticesEmergencyLoaded={noticesEmergencyLoaded}
                  setNoticesEmergencyLoaded={setNoticesEmergencyLoaded}
                  contentDataData={contentDataData}
                  noticesMainLoaded={noticesMainLoaded}
                  setNoticesMainLoaded={setNoticesMainLoaded}
                  NoticeTemplate={NoticeTemplate}
                  noticesBannerExpanded={noticesBannerExpanded}
                  toggleNoticesBanner={toggleNoticesBanner}
                  config={config}
                  pluginOptions={pluginOptions}
                  videoPlayerModalTriggered={videoPlayerModalTriggered}
                  setVideoPlayerModalTriggered={setVideoPlayerModalTriggered}
                />
              }
              onBeforeChangeFunction={(type, previous, next) => {
                return null;
              }}
              height={
                pluginOptions.heroSliderHeight
                  ? pluginOptions.heroSliderHeight
                  : 'calc(73vh - 50px)'
              }
              shouldDisplayButtons={false}
              slides={contentDataData[0].children[3].children}
            />
          ) : (
            <HeroInnerContent
              toggled={toggled}
              setToggled={setToggled}
              noticesEmergencyLoaded={noticesEmergencyLoaded}
              setNoticesEmergencyLoaded={setNoticesEmergencyLoaded}
              contentDataData={contentDataData}
              noticesMainLoaded={noticesMainLoaded}
              setNoticesMainLoaded={setNoticesMainLoaded}
              NoticeTemplate={NoticeTemplate}
              noticesBannerExpanded={noticesBannerExpanded}
              toggleNoticesBanner={toggleNoticesBanner}
              config={config}
              pluginOptions={pluginOptions}
              videoPlayerModalTriggered={videoPlayerModalTriggered}
              setVideoPlayerModalTriggered={setVideoPlayerModalTriggered}
            />
          )}
          <div className="mainContentWrapper home">
            <section className="app-content">
              <div className="container">
                <div className="row homepageWrapper">
                  <div className="col-12 col-8-m">
                    <ExtraHomepageContentTop
                      contentDataData={contentDataData}
                    />
                    <h2>{contentDataData[1].children[0].value}</h2>
                    <WigLoader
                      accroPressSiteID={config.accroPressSiteID}
                      gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                      gatsbyConfigPluginsAPOptions={
                        gatsbyConfigPluginsAPOptions
                      }
                      weducWebsitesID={config.weducWebsitesID}
                      weducContainer={config.weducContainerID}
                      weducEntity={config.weducEntityID}
                      contentDataData={contentDataData}
                      contentMetaData={contentMetaData}
                      contentDataArray="1"
                      contentDataChild="1"
                      AccroPressProdEndpoint={config.apiEndpoint}
                      AccroPressLocalEndpoint={config.apiEndpoint}
                      integratorID={config.integratorID}
                      integratorAccessToken={config.integratorAccessToken}
                      imageFormatting={
                        process.env.IMAGE_FORMATTING_SECRET ||
                        process.env.GATSBY_IMAGE_FORMATTING_SECRET
                      }
                    />

                    {pluginOptions.dairyDatesLocation === 'content' && (
                      <div
                        className={
                          'sidebarMenuItem sidebarMenuItem_Events weducWidgetDisplay_' +
                          calendarEventsLoaded
                        }
                      >
                        <div className="header">
                          <h3>
                            {pluginOptions.diaryDatesTitle || 'Upcoming Events'}
                          </h3>
                          {!pluginOptions.hideEventsPageRoute && (
                            <Link
                              to={
                                pluginOptions.eventsPageRoute ||
                                '/news-and-events/events'
                              }
                            >
                              View all
                            </Link>
                          )}
                        </div>
                        <WeducDairyDates
                          weducAPIURL={
                            pluginOptions.weducAPIURL
                              ? pluginOptions.weducAPIURL
                              : 'https://weduc.accropress.com/smartfeed/'
                          }
                          limit={5}
                          gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                          gatsbyConfigPluginsAPOptions={
                            gatsbyConfigPluginsAPOptions
                          }
                          loadedEvent={setCalendarEventsLoaded}
                          name={config.weducWebsitesID}
                          container={config.weducContainerID}
                          html={function CalendarTemplate(props) {
                            return (
                              <div
                                role="button"
                                onClick={() =>
                                  updateAndShowModal(
                                    props.data.title,
                                    props.data.description
                                  )
                                }
                                className="sidebarDataItem"
                              >
                                <span className="far fa-calendar"></span>
                                <div>
                                  {props.data.title}
                                  <span>
                                    {moment(props.data.start_date).format('LL')}
                                  </span>
                                </div>
                              </div>
                            );
                          }}
                          route={
                            !pluginOptions.hideEventsPageRoute
                              ? pluginOptions.eventsPageRoute ||
                                '/news-and-events/events'
                              : null
                          }
                        />
                      </div>
                    )}
                    {pluginOptions.newsfeedLocation === 'content' && (
                      <div
                        className={
                          'sidebarMenuItem sidebarMenuItem_News weducWidgetDisplay_' +
                          newsfeedLoaded
                        }
                      >
                        <div className="header">
                          <h3>{pluginOptions.newfeedTitle || 'Latest News'}</h3>
                          {!pluginOptions.hideNewsPageRoute && (
                            <Link
                              to={
                                pluginOptions.newsPageRoute ||
                                '/news-and-events/latest-news'
                              }
                            >
                              View all
                            </Link>
                          )}
                        </div>
                        <WeducNewsFeed
                          weducAPIURL={
                            pluginOptions.weducAPIURL
                              ? pluginOptions.weducAPIURL
                              : 'https://weduc.accropress.com/smartfeed/'
                          }
                          loadedEvent={setNewsfeedLoaded}
                          limit={5}
                          gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                          gatsbyConfigPluginsAPOptions={
                            gatsbyConfigPluginsAPOptions
                          }
                          name={config.weducWebsitesID}
                          container={config.weducContainerID}
                          html={function NewsFeedTemplate(props) {
                            console.debug('NewsFeedTemplate', props);
                            return (
                              <div
                                onClick={() =>
                                  updateAndShowModal(
                                    props.data.title,
                                    props.data.contentHtml,
                                    props.data.postType
                                  )
                                }
                                role="button"
                                className="sidebarDataItem"
                              >
                                <span className="far fa-newspaper"></span>
                                <div>
                                  {props.data.contentHtml
                                    .replace(/<\/p>]*>?/gm, ' ')
                                    .replace(/<[^>]*>?/gm, '')
                                    .substring(0, 30)}
                                  {props.data.contentHtml
                                    .replace(/<\/p>]*>?/gm, ' ')
                                    .replace(/<[^>]*>?/gm, '').length > 30
                                    ? '...'
                                    : ''}
                                  <span>
                                    {moment(props.data.createdAt).format('LL')}
                                  </span>
                                </div>
                              </div>
                            );
                          }}
                          route={
                            !pluginOptions.hideNewsPageRoute
                              ? pluginOptions.newsPageRoute ||
                                '/news-and-events/latest-news'
                              : null
                          }
                        />
                      </div>
                    )}

                    <div className="row">
                      {contentDataData[1] &&
                        contentDataData[1].children &&
                        contentDataData[1].children[2] &&
                        contentDataData[1].children[2].children &&
                        contentDataData[1].children[2].children.map(
                          (column, i) => (
                            <div className="col-12 col-6-m" key={i}>
                              <Link
                                to={column[3].value}
                                title={column[3].value}
                                className="quickLinkTile"
                                style={{
                                  backgroundImage: `url(${
                                    column[0].value
                                      ? returnResizedImageURL(
                                          { w: 385 },
                                          column[0].value
                                        )
                                      : ''
                                  })`,
                                }}
                              >
                                <div className="wrap">
                                  <div className="quickLinkText">
                                    <span>{column[1].value}</span>
                                    <span
                                      className={'fas ' + column[2].value}
                                    ></span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        )}
                    </div>
                  </div>

                  <div className="col-12 col-4-m">
                    {contentDataData[2] &&
                      contentDataData[2].children[3] &&
                      contentDataData[2].children[3].children &&
                      contentDataData[2].children[3].children[0] && (
                        <div className="sidebarMenuItem sidebarMenuItem_VoM">
                          <div
                            className="sidebarValue"
                            style={{
                              backgroundColor:
                                contentDataData[2].children[3].children[0][3]
                                  .color,
                              color:
                                contentDataData[2].children[3].children[0][4]
                                  .color,
                            }}
                          >
                            {contentDataData[2].children[3].children[0][0] &&
                              contentDataData[2].children[3].children[0][0]
                                .value && (
                                <span
                                  className="valueOfTheMonth_title title"
                                  style={{
                                    color:
                                      contentDataData[2].children[3]
                                        .children[0][4].color,
                                  }}
                                >
                                  {
                                    contentDataData[2].children[3]
                                      .children[0][0].value
                                  }
                                </span>
                              )}
                            {contentDataData[2].children[3].children[0][1] &&
                              contentDataData[2].children[3].children[0][1]
                                .value && (
                                <p
                                  className="valueOfTheMonth_content content"
                                  style={{
                                    color:
                                      contentDataData[2].children[3]
                                        .children[0][4].color,
                                  }}
                                >
                                  {
                                    contentDataData[2].children[3]
                                      .children[0][1].value
                                  }
                                </p>
                              )}
                            {contentDataData[2].children[3].children[0][2] &&
                              contentDataData[2].children[3].children[0][2]
                                .value && (
                                <span
                                  className="valueOfTheMonth_author author"
                                  style={{
                                    color:
                                      contentDataData[2].children[3]
                                        .children[0][4].color,
                                  }}
                                >
                                  {
                                    contentDataData[2].children[3]
                                      .children[0][2].value
                                  }
                                </span>
                              )}
                          </div>
                        </div>
                      )}

                    {pluginOptions.dairyDatesLocation !== 'content' && (
                      <div
                        className={
                          'sidebarMenuItem sidebarMenuItem_Events weducWidgetDisplay_' +
                          calendarEventsLoaded
                        }
                      >
                        <div className="header">
                          <h3>
                            {pluginOptions.diaryDatesTitle || 'Upcoming Events'}
                          </h3>
                          {pluginOptions.hideEventsPageRoute && (
                            <Link
                              to={
                                pluginOptions.eventsPageRoute ||
                                '/news-and-events/events'
                              }
                            >
                              View all
                            </Link>
                          )}
                        </div>
                        <WeducDairyDates
                          weducAPIURL={
                            pluginOptions.weducAPIURL
                              ? pluginOptions.weducAPIURL
                              : 'https://weduc.accropress.com/smartfeed/'
                          }
                          limit={5}
                          gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                          gatsbyConfigPluginsAPOptions={
                            gatsbyConfigPluginsAPOptions
                          }
                          loadedEvent={setCalendarEventsLoaded}
                          name={config.weducWebsitesID}
                          container={config.weducContainerID}
                          html={function CalendarTemplate(props) {
                            return (
                              <div
                                role="button"
                                onClick={() =>
                                  updateAndShowModal(
                                    props.data.title,
                                    props.data.description
                                  )
                                }
                                className="sidebarDataItem"
                              >
                                <span className="far fa-calendar"></span>
                                <div>
                                  {props.data.title}
                                  <span>
                                    {moment(props.data.start_date).format('LL')}
                                  </span>
                                </div>
                              </div>
                            );
                          }}
                          route={
                            !pluginOptions.hideEventsPageRoute
                              ? pluginOptions.eventsPageRoute ||
                                '/news-and-events/events'
                              : null
                          }
                        />
                      </div>
                    )}
                    <div className="sidebarMenuItem sidebarMenuItem_VoM">
                      <div className="header">
                        <h3>
                          {pluginOptions.valueOfTheMonthTitle ||
                            'Value of the Month'}
                        </h3>
                      </div>
                      <div className="sidebarValue">
                        <span className="valueOfTheMonth_title title">
                          {contentDataData[2].children[1].title}
                        </span>
                        <p className="valueOfTheMonth_content content">
                          {contentDataData[2].children[1].content}
                        </p>
                        {contentDataData[2].children[1].author &&
                          contentDataData[2].children[1].author !== '' && (
                            <span className="valueOfTheMonth_author author">
                              {contentDataData[2].children[1].author}
                            </span>
                          )}
                      </div>
                    </div>
                    {pluginOptions.newsfeedLocation !== 'content' && (
                      <div
                        className={
                          'sidebarMenuItem sidebarMenuItem_News weducWidgetDisplay_' +
                          newsfeedLoaded
                        }
                      >
                        <div className="header">
                          <h3>{pluginOptions.newfeedTitle || 'Latest News'}</h3>
                          {!pluginOptions.hideNewsPageRoute && (
                            <Link
                              to={
                                pluginOptions.newsPageRoute ||
                                '/news-and-events/latest-news'
                              }
                            >
                              View all
                            </Link>
                          )}
                        </div>
                        <WeducNewsFeed
                          weducAPIURL={
                            pluginOptions.weducAPIURL
                              ? pluginOptions.weducAPIURL
                              : 'https://weduc.accropress.com/smartfeed/'
                          }
                          loadedEvent={setNewsfeedLoaded}
                          limit={5}
                          gatsbyConfigSiteMetaData={gatsbyConfigSiteMetaData}
                          gatsbyConfigPluginsAPOptions={
                            gatsbyConfigPluginsAPOptions
                          }
                          name={config.weducWebsitesID}
                          container={config.weducContainerID}
                          html={function NewsFeedTemplate(props) {
                            return (
                              <div
                                onClick={() =>
                                  updateAndShowModal(
                                    props.data.title,
                                    props.data.contentHtml,
                                    props.data.postType
                                  )
                                }
                                role="button"
                                className="sidebarDataItem"
                              >
                                <span className="far fa-newspaper"></span>
                                <div>
                                  {props.data.contentHtml
                                    .replace(/<\/p>]*>?/gm, ' ')
                                    .replace(/<[^>]*>?/gm, '')
                                    .substring(0, 30)}
                                  {props.data.contentHtml
                                    .replace(/<\/p>]*>?/gm, ' ')
                                    .replace(/<[^>]*>?/gm, '').length > 30
                                    ? '...'
                                    : ''}
                                  <span>{props.data.createdAt}</span>
                                </div>
                              </div>
                            );
                          }}
                          route={
                            !pluginOptions.hideNewsPageRoute
                              ? pluginOptions.newsPageRoute ||
                                '/news-and-events/latest-news'
                              : null
                          }
                        />
                      </div>
                    )}
                    <ExtraHomepageContentBottom
                      contentDataData={contentDataData}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
}
